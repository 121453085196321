import ReactSelect, { components } from "react-select"
import { useTranslator } from "components/Translator"
import { Text } from "components/Text"
import cx from "classnames"
import { CaretDown } from "components/Icon/CaretDown"
import { Check } from "components/Icon/Check"
import { MenuItem } from "components/MenuItem"
import { MenuItemGroup } from "components/MenuItemGroup"
import { Tag } from "components/Tag"
import * as React from "react"
import PropTypes from "prop-types"
import { Times } from "components/Icon/Times"
import { InputBase } from "components/Input"
import { UserCircle } from "components/Icon/UserCircle"
import { Avatar } from "components/Avatar"
import { isTouchDevice } from "utils/isTouchDevice"
import { useBreakpoints } from "hooks/useBreakpoints"

export const Select = ({
  value: valueProps,
  defaultValue,
  onChange,
  placeholder,
  isSearchable,
  ...props
}) => {
  const translator = useTranslator()
  const [value, setValue] = React.useState(() => {
    if (valueProps) {
      return valueProps
    }

    if (defaultValue) {
      return defaultValue
    }

    return undefined
  })

  const handleChange = (selectedValues) => {
    setValue(selectedValues)

    if (onChange) {
      onChange(selectedValues)
    }
  }

  React.useEffect(() => {
    if (valueProps !== undefined) {
      setValue(valueProps)
    }
  }, [valueProps])

  const commonProps = {
    noOptionsMessage: () => translator.trans("no_options", null, "select"),
    placeholder: placeholder ?? translator.trans("placeholder", null, "select"),
    hideSelectedOptions: false,
    value,
    closeMenuOnSelect: !props.isMulti,
    styles: {
      input: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }),
    },
  }

  const { md } = useBreakpoints()

  const finalProps = {
    ...commonProps,
    ...props,
    components: {
      Control,
      ClearIndicator,
      DropdownIndicator,
      Group,
      IndicatorSeparator: () => null,
      MultiValue,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
      Input,
      ...props.components,
    },
    onChange: handleChange,
    isSearchable: isSearchable || (md && !isTouchDevice()),
  }

  return <SelectDesktop {...finalProps} />
}

const SelectDesktop = (props) => {
  return <ReactSelect {...props} menuPosition="fixed" />
}

export const SingleValue = (props) => {
  const { children, innerProps, isDisabled } = props

  return (
    <Text
      variant="tag"
      {...innerProps}
      className={cx(
        { "text-default": !isDisabled, "text-light": isDisabled },
        "truncate",
      )}
      style={{ lineHeight: 1 }}
    >
      {children}
    </Text>
  )
}

const ClearIndicator = (props) => {
  const { className, innerProps } = props

  return (
    <div {...innerProps} className={cx(className, "px-2")}>
      <Times className="w-3" />
    </div>
  )
}

const DropdownIndicator = (props) => {
  const { isDisabled, innerProps } = props

  return (
    <div {...innerProps} className="px-2">
      <CaretDown
        className={cx(
          {
            "text-light": isDisabled,
            "text-primary-dark": !isDisabled,
          },
          "w-3",
        )}
      />
    </div>
  )
}

const Control = (props) => {
  const { innerRef, innerProps, children, isDisabled } = props

  return (
    <InputBase ref={innerRef}>
      <div className="w-full">
        <div
          {...innerProps}
          className={cx("flex items-center space-x-2", {
            "bg-grey-light": isDisabled,
            "bg-white": !isDisabled,
          })}
        >
          {children}
        </div>
      </div>
    </InputBase>
  )
}

const ValueContainer = (props) => {
  const { children } = props

  return (
    <div className="flex overflow-hidden flex-wrap items-center leading-none box-border grow">
      {children}
    </div>
  )
}

const Placeholder = (props) => {
  const { children, innerProps } = props

  return (
    <div {...innerProps} className="leading-none text-light truncate">
      {children}
    </div>
  )
}

const Option = (props) => {
  const {
    isDisabled,
    isSelected,
    isFocused,
    innerRef,
    innerProps,
    isMulti,
    data,
  } = props

  return (
    <MenuItem
      label={data.label}
      caption={data.caption}
      disabled={isDisabled}
      selected={isSelected}
      focused={isFocused}
      onSelect={innerProps.onClick}
      ref={innerRef}
      endAdornment={
        <React.Fragment>
          {isMulti && isSelected ? (
            <Check className="w-4 text-primary-default" />
          ) : null}
          {data.img ? (
            <Avatar
              size="small"
              alt=""
              src={data.img.thumbs ? data.img.thumbs.w75 : data.img.url}
              fallback={<UserCircle className="w-4" />}
            />
          ) : null}
        </React.Fragment>
      }
      role="option"
    />
  )
}

const Group = (props) => {
  const { children, label } = props

  return <MenuItemGroup title={label}>{children}</MenuItemGroup>
}

const MultiValue = (props) => {
  const { children, innerProps, removeProps } = props

  return (
    <span className="inline-block my-0.5 mr-1 last:mr-0">
      <Tag
        color="default"
        tag="button"
        onClick={removeProps.onClick}
        label={children}
        iconEnd={<Times className="w-3" />}
        {...innerProps}
      />
    </span>
  )
}

const SelectButton = (props) => {
  const {
    state = "default",
    placeholder,
    value,
    disabled,
    onClick,
    components,
    onClear,
  } = props

  return (
    <div className={"relative"}>
      <button
        type="button"
        className="w-full text-left"
        disabled={disabled}
        onClick={onClick}
      >
        <InputBase state={state} disabled={disabled}>
          <div className="grow">
            {!value || value.length === 0 ? (
              <Text variant="body1" className="text-light">
                {placeholder}
              </Text>
            ) : value && Array.isArray(value) ? (
              value.map((object) => {
                return (
                  <span key={object.label} className="m-1">
                    <Tag
                      color="default"
                      label={object.label}
                      iconEnd={<Times className="w-3" />}
                    />
                  </span>
                )
              })
            ) : (
              <components.SingleValue variant="body1">
                {value.label}
              </components.SingleValue>
            )}
          </div>

          <CaretDown className="ml-auto text-primary-dark w-5 h-6" />
        </InputBase>
      </button>

      {onClear ? (
        <button
          type={"button"}
          onClick={onClear}
          className={"absolute top-1/2 -translate-y-1/2 right-12"}
        >
          <Times className={"w-5 h-6"} />
        </button>
      ) : null}
    </div>
  )
}

const valueShape = PropTypes.shape({ label: PropTypes.string })

SelectButton.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(["default", "success", "error"]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([valueShape, PropTypes.arrayOf(valueShape)]),
  placeholder: PropTypes.string,
}

const Input = (props) => {
  // This id is used to generate a random name and autoComplete value
  // to avoid autocomplete suggestions to pop on Safari
  // see https://gist.github.com/niksumeiko/360164708c3b326bd1c8?permalink_comment_id=3719706#gistcomment-3719706
  const id = React.useId()

  return <components.Input {...props} name={id} autoComplete={id} />
}
